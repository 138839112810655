<template>
  <v-dialog v-model="show" max-width="650" persistent>
    <r-card v-if="currentRemark">
      <template #header>
        <v-row no-gutters>
          <v-card-title>{{ $t('DialogsTitles.AddRemark') }}</v-card-title>
          <v-btn depressed v-if="$vuetify.breakpoint.mobile" class="ml-auto mr-4 mt-4" icon @click="show = false">
            <v-icon dark>mdi-close-thick</v-icon>
          </v-btn>
        </v-row>
      </template>
      <v-card-text>
        <v-form v-model="isValid" ref="createRemarkRef">
          <v-row>
            <v-col cols="12">
              <v-textarea v-model="currentRemark.text" :rules="[rules.required]" dense :label="$t('RiskHeaders.Remark')"></v-textarea>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions class="d-block d-md-flex mx-4">
        <v-btn depressed :block="$vuetify.breakpoint.mobile" @click="show = false" class="my-4">{{ $t('ButtonsLabel.Cancel') }}</v-btn>
        <v-btn depressed :block="$vuetify.breakpoint.mobile" @click="AddRemark" color="success" class="ml-0 ml-md-4 my-4" :disabled="!isValid">
          <v-icon left>mdi-check</v-icon>
          {{ $t('DialogsTitles.AddRemark') }}
        </v-btn>
      </v-card-actions>
    </r-card>
  </v-dialog>
</template>

<script>
import apiService from '@/services/apiService.js';
import { mapGetters } from 'vuex';
export default {
  data: () => ({
    currentRemark: null,
    show: false,
    isValid: null,
    rules: {
      required: (value) => !!value || 'Field cannot be empty.',
    },
  }),
  computed: {
    ...mapGetters(['user']),
  },
  methods: {
    async AddRemark() {
      await apiService.putRemark(this.currentRemark);
      this.$emit('remark-added');
      this.show = false;
    },
    createRisk(RiskOriginalId) {
      this.currentRemark = {
        text: null,
        owner: this.user,
        riskOriginalId: RiskOriginalId,
      };
      this.show = true;
    },
  },
};
</script>

