<template>
  <v-container fluid v-if="historyLoaded">
    <v-row>
      <v-col>
        <v-btn text plain class="pa-0 align-center" @click="$router.go(-1)"><v-icon>mdi-arrow-left</v-icon>{{ $t('ButtonsLabel.GoBack') }}</v-btn>
      </v-col>
    </v-row>
    <v-row no-gutters align="center" class="mt-4">
      <v-col cols="12" sm="8">
        <v-row no-gutters class="text-h4 font-weight-bold">
          <p>{{ currentRisk.title }}</p>
          &nbsp;
          <h6 v-if="currentRisk.commitStatus == 2" class="primary--text">{{ $t('PageTitles.NewestRisk') }}</h6>
          <h6 v-if="currentRisk.commitStatus != 2" class="warning--text">{{ $t('PageTitles.Deprecated') }}</h6>
        </v-row>
      </v-col>
      <v-col cols="4" align="right">
        <v-btn
          v-if="HasReadWritePermissions"
          :loading="loading"
          :block="isMobile"
          depressed
          @click="EditRisk"
          :color="$vuetify.theme.dark ? 'black' : 'white'"
          class="align-self-center ml-md-4 mr-4"
        >
          <v-icon left>mdi-pencil-outline</v-icon>
          {{ $t('ButtonsLabel.Edit') }}
        </v-btn>
        <v-btn
          depressed
          @click="save"
          :disabled="saveIsDisabled"
          v-if="HasReadWritePermissions"
          :loading="loading"
          :color="$vuetify.theme.dark ? 'black' : 'white'"
        >
          <v-icon class="mr-2">mdi-content-save-outline</v-icon>
          {{ $t('ButtonsLabel.Save') }}
        </v-btn>
      </v-col>
    </v-row>
    <!-- Important values data table -->
    <v-simple-table class="mt-4 px-4">
      <v-row no-gutters class="py-2">
        <v-col cols="12" md="2" class="font-weight-bold">
          {{ $t('RiskDetails.RiskTitle') }}
        </v-col>
        <v-col cols="12" md="9" class="d-flex align-center">
          <p class="mb-0">{{ currentRisk.title }}</p>
        </v-col>
      </v-row>
      <v-row no-gutters class="py-2">
        <v-col cols="12" md="2" class="font-weight-bold">
          {{ $t('RiskDetails.Description') }}
        </v-col>
        <v-col cols="12" md="9" class="d-flex align-center">
          <p class="mb-0">{{ currentRisk.consequenceDescription }}</p>
        </v-col>
      </v-row>
      <v-row no-gutters class="py-2">
        <v-col cols="12" md="2" class="font-weight-bold">
          {{ $t('RiskDetails.RiskOwner') }}
        </v-col>
        <v-col cols="12" md="9" class="d-flex align-center">
          <router-link v-if="AdminOrIsInSameCompany" :to="{ name: 'UserDetails', params: { Id: currentRisk.owner.id } }">
            {{ currentRisk.owner.name }}
          </router-link>
          <p v-else>{{ currentRisk.owner.name }}</p>
        </v-col>
      </v-row>
    </v-simple-table>
    <!-- Sliders data table -->
    <v-simple-table class="mt-4 px-4">
      <v-row no-gutters class="py-2">
        <v-col cols="12" md="2">
          <v-row no-gutters>
            <v-col cols="4" class="font-weight-bold">
              {{ $t('RiskDetails.Consequence') }}
            </v-col>
          </v-row>
        </v-col>

        <v-col cols="12" md="10">
          <v-row no-gutters class="text-center">
            <v-col :class="{ 'mb-4 px-4': isMobile }" cols="12" md="2" class="text-center">
              {{ $t('RiskDetails.Probability') }}
              <v-slider
                class="large-slider"
                v-model.number="currentRisk.probability"
                :min="sliderMin - 1"
                :max="sliderMax + 1"
                :color="$util.colorize(currentRisk.probability)"
                :vertical="!isMobile"
                :tick-labels="['Kan indtræffe', '', '', '', '', '', 'Er indtruffet']"
                ticks="always"
                hide-details
                :thumb-size="24"
                :readonly="!HasReadWritePermissions"
                :thumb-label="currentRisk.probability == 0 || currentRisk.probability == 6 ? false : 'always'"
              />
            </v-col>
            <v-col :class="{ 'mb-4 px-4': isMobile }" cols="12" md="2">
              {{ $t('RiskDetails.TimeLine') }}
              <v-slider
                tick-size="0"
                class="mt-9"
                v-model.number="currentRisk.timeLine"
                :min="sliderMin"
                :max="sliderMax"
                :color="$util.colorize(currentRisk.timeLine)"
                :vertical="!isMobile"
                :thumb-label="isMobile ? false : 'always'"
                :tick-labels="isMobile ? [1, 2, 3, 4, 5] : []"
                hide-details
                :thumb-size="24"
                ticks="always"
                :readonly="!HasReadWritePermissions"
                :disabled="currentRisk.probability == 0 || currentRisk.probability == 6"
              />
            </v-col>
            <v-col :class="{ 'mb-4 px-4': isMobile }" cols="12" md="2">
              {{ $t('RiskDetails.Expenses') }}
              <v-slider
                class="mt-9"
                v-model.number="currentRisk.expenses"
                :min="sliderMin"
                :max="sliderMax"
                :color="$util.colorize(currentRisk.expenses)"
                :vertical="!isMobile"
                :thumb-label="isMobile ? false : 'always'"
                :tick-labels="isMobile ? [1, 2, 3, 4, 5] : []"
                hide-details
                :thumb-size="24"
                ticks="always"
                :readonly="!HasReadWritePermissions"
                :disabled="currentRisk.probability == 0 || currentRisk.probability == 6"
              />
            </v-col>
            <v-col :class="{ 'mb-4 px-4': isMobile }" cols="12" md="2">
              {{ $t('RiskDetails.Scope') }}
              <v-slider
                class="mt-9"
                v-model.number="currentRisk.scope"
                :min="sliderMin"
                :max="sliderMax"
                :color="$util.colorize(currentRisk.scope)"
                :vertical="!isMobile"
                :thumb-label="isMobile ? false : 'always'"
                :tick-labels="isMobile ? [1, 2, 3, 4, 5] : []"
                hide-details
                :thumb-size="24"
                ticks="always"
                :readonly="!HasReadWritePermissions"
                :disabled="currentRisk.probability == 0 || currentRisk.probability == 6"
              />
            </v-col>
            <v-col :class="{ 'mb-4 px-4': isMobile }" cols="12" md="2">
              {{ $t('RiskDetails.Resources') }}
              <v-slider
                class="mt-9"
                v-model.number="currentRisk.resource"
                :min="sliderMin"
                :max="sliderMax"
                :color="$util.colorize(currentRisk.resource)"
                :vertical="!isMobile"
                :thumb-label="isMobile ? false : 'always'"
                :tick-labels="isMobile ? [1, 2, 3, 4, 5] : []"
                hide-details
                :thumb-size="24"
                ticks="always"
                :readonly="!HasReadWritePermissions"
                :disabled="currentRisk.probability == 0 || currentRisk.probability == 6"
              />
            </v-col>
            <v-col cols="12" md="2">
              <v-row no-gutters class="justify-center">
                <h3 class="text-center">Overordnet Konsekvens</h3>
              </v-row>
              <v-card class="mx-auto mt-5 d-flex" height="125" width="125" :color="$util.colorize(callConsequenceCalculation, true)">
                <div class="mx-auto my-auto">{{ callConsequenceCalculation }}</div>
              </v-card>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-simple-table>
    <!--  -->
    <v-row no-gutters :class="{ 'mt-4 text-center d-block': isMobile, 'mt-4': !isMobile }" justify="center">
      <v-col class="text-center" cols="12" md="2">1: {{ $t('RiskConsequenceText.VerySmall') }}</v-col>
      <v-col class="text-center" cols="12" md="2">2: {{ $t('RiskConsequenceText.Small') }}</v-col>
      <v-col class="text-center" cols="12" md="2">3: {{ $t('RiskConsequenceText.Medium') }}</v-col>
      <v-col class="text-center" cols="12" md="2">4: {{ $t('RiskConsequenceText.Big') }}</v-col>
      <v-col class="text-center" cols="12" md="2">5: {{ $t('RiskConsequenceText.VeryBig') }}</v-col>
    </v-row>

    <!-- Mitigation data table -->
    <v-simple-table class="mt-4 px-4">
      <v-row no-gutters class="py-2">
        <v-col cols="12" md="2" class="font-weight-bold">
          {{ $t('ProjectListHeaders.Created') }}
        </v-col>
        <v-col cols="12" md="9" class="d-flex align-center">
          <p class="mb-0">{{ $util.formatDate(currentRisk.created, true) }}</p>
        </v-col>
      </v-row>
      <v-row no-gutters class="py-2">
        <v-col cols="12" md="2" class="font-weight-bold">
          {{ $t('RiskHeaders.RiskStatus') }}
        </v-col>
        <v-col cols="12" md="9" class="d-flex align-center">
          <p class="mb-0">{{ $util.RiskStatusByNumber(currentRisk.riskStatus) }}</p>
        </v-col>
      </v-row>
      <v-row no-gutters class="py-2">
        <v-col cols="12" md="2" class="font-weight-bold">
          {{ $t('RiskHeaders.RiskType') }}
        </v-col>
        <v-col cols="12" md="9" class="d-flex align-center">
          <p class="mb-0">{{ $util.RiskTypeByNumber(currentRisk.type) }}</p>
        </v-col>
      </v-row>
      <v-row no-gutters class="py-2">
        <v-col cols="12" md="2" class="font-weight-bold">
          {{ $t('RiskHeaders.CommitStatus') }}
        </v-col>
        <v-col cols="12" md="9" class="d-flex align-center">
          <p class="mb-0">{{ $util.CommitStatusByNumber(currentRisk.commitStatus) }}</p>
        </v-col>
      </v-row>
      <v-row no-gutters class="py-2">
        <v-col cols="12" md="2" class="font-weight-bold">
          {{ $t('RiskDetails.Mitigation') }}
        </v-col>
        <v-col cols="12" md="9" class="d-flex align-center">
          <p class="mb-0">{{ currentRisk.mitigation }}</p>
        </v-col>
      </v-row>
      <v-row no-gutters class="py-2">
        <v-col cols="12" md="2" class="font-weight-bold">
          {{ $t('RiskDetails.ContingencyPlan') }}
        </v-col>
        <v-col cols="12" md="9" class="d-flex align-center">
          <p class="mb-0">{{ currentRisk.contingencyPlan }}</p>
        </v-col>
      </v-row>
    </v-simple-table>

    <!-- Graph data table -->

    <v-sheet class="mt-6" rounded>
      <LineChart v-if="historyLoaded && previousRisks.length > 1" :chartData="chartData" :options="chartOptions"></LineChart>
    </v-sheet>

    <v-row no-gutters>
      <v-col cols="5" class="pr-2 mt-8">
        <v-row no-gutters align="center">
          <v-col cols="2" sm="4">
            <h2>{{ $t('RiskDetails.Remarks') }}</h2>
          </v-col>
          <v-col cols="6"></v-col>
          <v-col cols="2" class="d-flex justify-end">
            <v-btn @click="addRemark" depressed :color="$vuetify.theme.dark ? 'black' : 'white'">
              <v-icon left>mdi-plus-box-outline</v-icon>
              {{ $t('ButtonsLabel.AddRemark') }}
            </v-btn>
          </v-col>
        </v-row>
        <v-sheet class="px-4 mt-5" rounded>
          <v-col cols="12" v-if="!isAdmin">
            <v-row v-for="(remark, i) in remarks" :key="i">
              <v-col cols="3">
                <router-link v-if="remark.owner" :to="{ name: 'UserDetails', params: { Id: remark.owner.id } }">
                  {{ remark.owner.name }}
                </router-link>
                <br />
                {{ $util.formatDate(remark.created, true) }}
              </v-col>
              <v-col cols="9" class="mb-2 pb-0">
                <v-textarea outlined dense readonly v-model="remark.text"></v-textarea>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" v-if="isAdmin">
            <v-row v-for="(remark, i) in remarks" :key="i">
              <v-col cols="3">
                <router-link v-if="remark.owner" :to="{ name: 'UserDetails', params: { Id: remark.owner.id } }">
                  {{ remark.owner.name }}
                </router-link>
                <br />
                {{ $util.formatDate(remark.created, true) }}
              </v-col>
              <v-col cols="7" class="mb-2 pb-0">
                <v-textarea outlined dense readonly v-model="remark.text"></v-textarea>
              </v-col>
              <v-col cols="2">
                <v-btn @click="deleteRemark(remark)" icon :title="$t('ButtonsLabel.DeleteRemark')">
                  <v-icon>mdi-delete</v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-col>
        </v-sheet>
      </v-col>
      <v-col cols="7" class="pt-4 pl-2">
        <v-row no-gutters align="center">
          <v-col cols="2" sm="4">
            <h2>{{ $t('PageTitles.RiskHistory') }}</h2>
          </v-col>
          <v-col cols="4">
            <v-text-field outlined hide-details dense v-model="searchHistory" :label="$t('message.Search')" class="pa-4" background-color="searchField" />
          </v-col>
        </v-row>
        <v-data-table
          sort-by="version"
          :items="previousRisks"
          :search="searchHistory"
          :headers="getHistoryHeaders"
          :items-per-page="5"
          sort-desc
          @click:row="LookDeprecated"
        >
          <template #[`item.author`]="{ item }">
            <router-link v-if="item.author" :to="{ name: 'UserDetails', params: { Id: item.author.id } }">
              {{ item.author.name }}
            </router-link>
          </template>
          <template #[`item.created`]="{ item }">
            {{ $util.formatDate(item.created, true) }}
          </template>
        </v-data-table>
      </v-col>
      <!-- Log data table -->
    </v-row>

    <create-edit-risk ref="CreateEditRiskRef" @risk-updated="getRisk" />
    <create-remark ref="createRemarkRef" @remark-added="editRemark" />
  </v-container>
</template>

<script>
import LineChart from '@/components/charts/LineChart.vue';
import CreateEditRisk from '@/components/Dialogs/CreateEditRisk.vue';
import CreateRemark from '@/components/Dialogs/CreateRemark.vue';
import apiService from '@/services/apiService.js';
import { mapGetters } from 'vuex';
import _ from 'lodash';
export default {
  components: {
    CreateEditRisk,
    CreateRemark,
    LineChart,
  },
  name: 'risk_details',
  data: () => ({
    loading: false,
    sliderMin: 1,
    sliderMax: 5,
    refreshChart: 0,
    currentRisk: {},
    activeItem: {},
    previousRisks: [],
    remarks: [],
    project: {},
    searchHistory: null,
    isEditing: false,
    historyLoaded: false,
    chartOptions: {
      responsive: true,
      maintainAspectRatio: false,
    },
    AdminOrIsInSameCompany: false,
    rules: {
      required: (value) => !!value || 'Field cannot be empty.',
    },
  }),
  computed: {
    ...mapGetters(['isManager', 'isAdmin', 'user', 'currentProjectId']),
    callConsequenceCalculation() {
      const impactTotal = this.$util.consequenceCalculator(
        this.currentRisk.timeLine,
        this.currentRisk.expenses,
        this.currentRisk.scope,
        this.currentRisk.resource,
        this.currentRisk.probability
      );
      return impactTotal;
    },
    isMobile() {
      return this.$vuetify.breakpoint.mobile;
    },
    getHistoryHeaders() {
      const t = this.$t.bind(this);
      return [
        { text: t('RiskListHeaders.EditedBy'), value: 'author' },
        { text: t('ProjectListHeaders.Created'), value: 'created' },
        { text: t('RiskListHeaders.Version'), value: 'version' },
        { text: t('RiskListHeaders.Probability'), value: 'probability' },
        { text: t('RiskListHeaders.TimePlan'), value: 'timeLine' },
        { text: t('RiskListHeaders.Expenses'), value: 'expenses' },
        { text: t('RiskListHeaders.Scope'), value: 'scope' },
        { text: t('RiskListHeaders.Resources'), value: 'resource' },
      ];
    },
    HasReadWritePermissions() {
      if (this.isAdmin) {
        return true;
      }
      if (this.$util.HasReadAndWrite(this.user, this.currentRisk.projectPermissions) == false) {
        return false;
      } else if (this.AdminOrIsInSameCompany) {
        if (this.isManager) {
          return true;
        } else {
          return false;
        }
      } else {
        return true;
      }
    },
    chartData() {
      const t = this.$t.bind(this);
      const ProbabilityText = t('RiskListHeaders.Probability');
      const TimePlanText = t('RiskListHeaders.TimePlan');
      const ExpensesText = t('RiskListHeaders.Expenses');
      const ScopeText = t('RiskListHeaders.Scope');
      const ResourceText = t('RiskListHeaders.Resources');

      const dates = this.previousRisks.map((x) => this.$util.formatDate(x.updated, true));
      const Probability = this.previousRisks.map((x) => x.probability);
      const TimePlan = this.previousRisks.map((x) => x.timeLine);
      const Expenses = this.previousRisks.map((x) => x.expenses);
      const Scope = this.previousRisks.map((x) => x.scope);
      const Resource = this.previousRisks.map((x) => x.resource);
      return {
        labels: dates,
        datasets: [
          {
            label: ProbabilityText,
            data: Probability,
            fill: false,
            borderColor: 'rgb(44,144,192)',
            tension: 0.1,
          },
          {
            label: TimePlanText,
            data: TimePlan,
            fill: false,
            borderColor: 'rgb(244,204,92)',
            tension: 0.1,
          },
          {
            label: ExpensesText,
            data: Expenses,
            fill: false,
            borderColor: 'rgb(204,68,44)',
            tension: 0.1,
          },
          {
            label: ScopeText,
            data: Scope,
            fill: false,
            borderColor: 'rgb(108,76,124)',
            tension: 0.1,
          },
          {
            label: ResourceText,
            data: Resource,
            fill: false,
            borderColor: 'rgb(2,122,18)',
            tension: 0.1,
          },
        ],
      };
    },
    saveIsDisabled() {
      if (
        this.currentRisk.probability != this.activeItem.probability ||
        this.currentRisk.resource != this.activeItem.resource ||
        this.currentRisk.scope != this.activeItem.scope ||
        this.currentRisk.timeLine != this.activeItem.timeLine ||
        this.currentRisk.expenses != this.activeItem.expenses
      ) {
        return false;
      } else {
        return true;
      }
    },
  },
  methods: {
    goBack() {
      this.$router.push({ name: 'RiskList' });
    },
    async getRisk(getDepricated) {
      if (this.$route.name == 'RiskDetails' && this.$route.params.riskId != null) {
        this.currentRisk = await apiService.getRiskByriskOriginalId(this.$route.params.riskId);
        this.activeItem = _.cloneDeep(this.currentRisk);
      } else if (this.$route.name == 'RiskVersionDetails' && this.$route.params.historyId != null) {
        if (!getDepricated) {
          this.currentRisk = await apiService.getRiskById(this.$route.params.historyId);
          this.activeItem = _.cloneDeep(this.currentRisk);
        } else if (getDepricated) {
          this.currentRisk = await apiService.getRiskByriskOriginalId(this.currentRisk.riskOriginalId);
          this.activeItem = _.cloneDeep(this.currentRisk);
        }
      }
      this.project = await apiService.getProjectById(this.currentProjectId);
      this.IsAdminOrIsInSameCompany();
      this.getHistory();
      this.getRemarks();
      this.refreshChart = this.refreshChart + 1;
    },
    async save() {
      this.loading = true;
      await apiService.putRisk(this.currentRisk);
      this.getRisk();
      this.loading = false;
    },
    getHistory() {
      apiService.getRiskHistory(this.currentRisk.riskOriginalId).then((data) => {
        this.previousRisks = data;
        this.historyLoaded = true;
      });
    },
    async getRemarks() {
      this.remarks = await apiService.getRemarks(this.currentRisk.riskOriginalId);
    },
    EditRisk() {
      this.$refs.CreateEditRiskRef.editRisk(this.currentRisk);
    },
    async LookDeprecated(oldRisk) {
      this.currentRisk = await apiService.getDeprecatedRisk(oldRisk.id);
      window.scrollTo(0, 0);
    },
    addRemark() {
      this.$refs.createRemarkRef.createRisk(this.currentRisk.riskOriginalId);
    },
    editRemark() {
      this.getRisk();
    },
    async IsAdminOrIsInSameCompany() {
      if (this.isAdmin) {
        this.AdminOrIsInSameCompany = true;
      }
      if (this.currentRisk.projectCompanyId != null) {
        if (this.project.companyId == this.user.companyId) {
          this.AdminOrIsInSameCompany = true;
        } else {
          this.AdminOrIsInSameCompany = false;
        }
      } else {
        this.AdminOrIsInSameCompany = false;
      }
    },
    async deleteRemark(remark) {
      await apiService.deleteRemark(remark.id);
      this.getRisk();
    },
  },
  created() {
    if (
      (this.$route.name == 'RiskDetails' && this.$route.params.riskId != null) ||
      (this.$route.name == 'RiskVersionDetails' && this.$route.params.historyId != null)
    ) {
      this.getRisk();
    } else {
      return this.$router.push({ name: 'baseProject' });
    }
  },
};
</script>
<style>
.large-slider .v-slider {
  height: 225px;
}
</style>
