<template>
  <v-dialog v-model="show" max-width="650" persistent>
    <r-card v-if="currentRisk">
      <template #header>
        <v-row no-gutters>
          <v-card-title>{{ isEditing ? $t('DialogsTitles.EditRisk') : $t('DialogsTitles.AddRisk') }} {{ isEditing ? title : '' }}</v-card-title>
          <v-btn depressed v-if="$vuetify.breakpoint.mobile" class="ml-auto mr-4 mt-4" icon @click="show = false">
            <v-icon dark>mdi-close-thick</v-icon>
          </v-btn>
        </v-row>
      </template>
      <v-card-text>
        <v-form v-model="isValid" ref="editRiskRef">
          <v-row>
            <v-col cols="12" v-if="isManager">
              <v-select dense :items="projects" item-text="name" item-value="id" v-model="currentRisk.projectId" />
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field v-model="currentRisk.title" :rules="[rules.required]" dense :label="$t('RiskHeaders.RiskTitle')"></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field v-model="currentRisk.consequenceDescription" dense :label="$t('RiskHeaders.Description')"></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-select
                dense
                :items="users"
                item-text="name"
                :rules="[rules.required]"
                return-object
                v-model="selectedItem"
                :label="$t('RiskHeaders.RiskOwner')"
              ></v-select>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field v-model="currentRisk.mitigation" dense :label="$t('RiskHeaders.Mitigation')"></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field v-model="currentRisk.contingencyPlan" dense :label="$t('RiskHeaders.ContingencyPlan')"></v-text-field>
            </v-col>
            <v-col cols="12" md="6" v-if="!isEditing">
              <v-text-field v-model="currentRemark" dense :label="$t('RiskHeaders.Remarks')"></v-text-field>
            </v-col>

            <v-col cols="12" md="6">
              <v-select
                v-model="currentRisk.riskStatus"
                :items="riskStatus"
                item-value="id"
                item-text="text"
                :rules="[rules.required]"
                dense
                :label="$t('RiskHeaders.RiskStatus')"
              />
            </v-col>
            <v-col cols="12" md="6">
              <v-select
                v-model="currentRisk.type"
                :items="riskType"
                item-value="id"
                item-text="text"
                :rules="[rules.required]"
                dense
                :label="$t('RiskHeaders.RiskType')"
              />
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions class="d-block d-md-flex mx-4">
        <v-btn depressed :block="$vuetify.breakpoint.mobile" @click="show = false" class="my-4">{{ $t('ButtonsLabel.Cancel') }}</v-btn>
        <v-btn
          depressed
          :block="$vuetify.breakpoint.mobile"
          @click="savecurrentRisk"
          color="success"
          class="ml-0 ml-md-4 my-4"
          :loading="isSaving"
          :disabled="!isValid"
        >
          <v-icon left>mdi-check</v-icon>
          {{ isEditing ? $t('DialogsTitles.EditRisk') : $t('DialogsTitles.AddRisk') }}
        </v-btn>
      </v-card-actions>
    </r-card>
  </v-dialog>
</template>

<script>
import _ from 'lodash';
import apiService from '@/services/apiService';
import { mapGetters } from 'vuex';
export default {
  data: () => ({
    projects: [],
    selectedProject: null,
    selectedItem: null,
    show: false,
    users: [],
    currentRisk: {},
    currentRemark: null,
    title: null,
    isValid: false,
    isSaving: false,
    rules: {
      required: (value) => !!value || 'Field cannot be empty.',
    },
  }),
  computed: {
    ...mapGetters(['isManager', 'isAdmin', 'user', 'currentProjectId']),
    isEditing() {
      return this.currentRisk?.id ? true : false;
    },
    riskStatus() {
      const t = this.$t.bind(this);
      return [
        { text: t('RiskStatusText.None'), id: 0 },
        { text: t('RiskStatusText.Risk'), id: 1 },
        { text: t('RiskStatusText.DidNotOccur'), id: 2 },
        { text: t('RiskStatusText.Occurred'), id: 3 },
      ];
    },
    riskType() {
      const t = this.$t.bind(this);
      return [
        { text: t('RiskType.None'), id: 0 },
        { text: t('RiskType.Organization'), id: 1 },
        { text: t('RiskType.TechnicalSolution'), id: 2 },
        { text: t('RiskType.Suppliers'), id: 3 },
        { text: t('RiskType.Stakeholders'), id: 4 },
        { text: t('RiskType.GeneralUncertainty'), id: 5 },
        { text: t('RiskType.ProjectCertainty'), id: 6 },
        { text: t('RiskType.Uncategorized'), id: 7 },
      ];
    },
  },
  methods: {
    async savecurrentRisk() {
      this.currentRisk.owner = this.selectedItem;
      if (!this.isEditing) {
        if (this.currentRemark != null) {
          const newRemark = {
            text: this.currentRemark,
            owner: this.user,
          };
          this.currentRisk.remarks.push(newRemark);
        }
      }
      this.isSaving = true;
      await apiService
        .putRisk(this.currentRisk)
        .then(() => {
          this.show = false;
          this.$emit('risk-updated');
        })
        .finally(() => {
          this.isSaving = false;
        });
    },
    async GetProjects() {
      this.projects = await apiService.getProjects();
      if (!this.isEditing) this.currentRisk.projectId = this.$route.params.projectId;
    },
    async GetUsers() {
      if (this.isManager) {
        const data = await apiService.getUserCompany();
        this.users = data.users;
      }
    },
    editRisk(risk) {
      this.GetUsers();
      this.currentRisk = _.cloneDeep(risk);
      this.title = this.currentRisk.title;
      this.show = true;
      this.selectedItem = this.currentRisk.owner;
      this.GetProjects();
    },
    createRisk() {
      this.currentRisk = {
        title: '',
        consequenceDescription: '',
        type: 0,
        probability: 1,
        timeline: 1,
        expenses: 1,
        scope: 1,
        resource: 1,
        riskStatus: 0,
        owner: '',
        ownerId: null,
        mitigation: '',
        contingencyPlan: '',
        remarks: [],
        commitStatus: 2,
        projectId: '',
      };
      this.show = true;
      this.GetProjects();
      this.GetUsers();
    },
  },
};
</script>

<style></style>
